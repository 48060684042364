html {
  min-height: 100%;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
}

#webpack-dev-server-client-overlay {
  display: none;
}

#root {
  flex-grow: 1;
  display: flex;
  height: 100%;
  width: 100%;
}

.MuiPickersCalendar-transitionContainer {
  margin-bottom: 12px;
}

.MuiSpeedDial-actionsClosed {
  display: none !important;
}

#webpack-dev-server-client-overlay {
  display: none;
}
